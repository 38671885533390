import React, {useEffect} from "react"
import {Card, CardBody, Col, Progress, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,} from "reactstrap";
import * as url from "../../../helpers/jengasmart_api_urls";
import axios from "axios";
import {useState} from "react";
import {useParams} from "react-router-dom";
import {Bar} from "react-chartjs-2";
import FeatherIcon from "feather-icons-react";
export default function ProgressReport(props){
    const [graphLoading, setGraphLoading] = useState(true);
    const [stats, setStats] = useState({});
    const [open, setOpen] = useState('0');
    const[tasksList, setTasksList]=useState([])
    const[completedTasksList, setCompletedTasksList]=useState([])
    const[completedTasks, setCompletedTasks] = useState([])
    const[completedTasksCount, setCompletedTasksCount] = useState([])
    const[projectDetails, setProjectDetails]= useState([])
    const {id} = useParams()
    var stats_url = process.env.REACT_APP_API_BASE + url.GET_STATS + '/project/' + id;
    var progress_url =  process.env.REACT_APP_API_BASE + url.GET_STATS + '/tasks/' + id;
    const TASK_URL = process.env.REACT_APP_API_BASE + url.POST_TASKS_DETAILS + "/project/" + id;
    const MILESTONE_URL = process.env.REACT_APP_API_BASE + "milestones/project/" + props.projectId + "?page=1";
    const PARENT_TASK_URL = process.env.REACT_APP_API_BASE + "parenttasks/project/" + id
    const SUB_TASK_URL = process.env.REACT_APP_API_BASE +  "subtasks/project/" + id;
    const [openTask, setOpenTask] = useState(null); // State to track the open task
    const [openSubTask, setOpenSubTask] = useState(null); // State to track the open sub task
    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };


    const toggleTask = (taskId) => {
        setOpenTask(openTask === taskId ? null : taskId); // Toggle the open task
    };
    const toggleSubTask = (subTaskId) => {
        setOpenSubTask(openSubTask === subTaskId ? null : subTaskId); // Toggle the open task
    };
    const getTasks = async () => {
        const parentTasks = await axios.get(PARENT_TASK_URL, config)
        const subTask = await axios.get(SUB_TASK_URL, config)

    }
    const getStats = async () => {
        const statValues = await axios.get(stats_url, config)
        const progressValues = await axios.get(progress_url, config)
        setCompletedTasks(progressValues.data)
        setCompletedTasksCount(progressValues.data.completedTasksCountByMonth)
        setTasksList(progressValues.data.tasksLists)
        setCompletedTasksList(progressValues.data.completedTasksLists)
        setProjectDetails(progressValues.data.projectDetails)
        setStats(statValues.data.data);
        setGraphLoading(false)
    }
    const tasksCompletedData = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep","Oct","Nov","Dec"],
        datasets: [
            {
                backgroundColor: "#194973",
                borderColor: "#194973",
                borderWidth: 1,
                hoverBackgroundColor: "#194973",
                hoverBorderColor: "#194973",
                label: 'Completed Tasks',
                data: completedTasksCount,
            },
        ],
    }

    const option = {
        scales: {
            dataset: [
                {
                    barPercentage: 0.4,
                },
            ],
            xAxes: [{
                gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                }
            }],
            yAxes: [{
                gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                },
                ticks: {
                    beginAtZero: true,
                    min: 0, // Set the minimum value of the y-axis scale to zero
                },
            }],
        },
    }
    useEffect(()=>{
        getStats()
        getTasks()
    }, [])
    return (
        <>
            <div className={"container-fluid px-3"}>
                <div className="d-flex flex-row flex-md-wrap w-100 gap-2 mb-3">
                    <Col className="p-3 rounded-3 border flex-grow-1">
                        <h6 className="stats-text">
                           Progress Status
                        </h6>
                        <p className="stats-value fs-5 mb-0">
                            Active
                        </p>
                    </Col>
                    <Col className="p-3 rounded-3 border flex-grow-1">
                        <h6 className="stats-text">
                           Start Date
                        </h6>
                        <p className="stats-value fs-5 mb-0">
                            {new Date(projectDetails.createdAt).toLocaleDateString()}
                        </p>
                    </Col>
                    <Col className="p-3 rounded-3 border flex-grow-1">
                        <h6 className="stats-text">
                            Due Date
                        </h6>
                        <p className="stats-value fs-5 mb-0">
                            {new Date(projectDetails.endDate).toLocaleDateString()}
                        </p>
                    </Col>
                    <Col className="p-3 rounded-3 border flex-grow-1">
                        <h6 className="stats-text">
                            Timeline
                        </h6>
                        <p className="stats-value fs-5 mb-0">
                            {Math.floor( ( new Date(projectDetails.endDate) - new Date())/ (1000 * 60 * 60 * 24)) } Days Left
                        </p>
                    </Col>
                </div>
                <Card className="p-3 rounded-3 border black-50-bg w-100 h-100 mb-0 progress-section">
                    <CardBody>
                        <div className="d-flex flex-row justify-content-between">
                            <h5>Progress Summary</h5>
                            <h6>Open Scheduler</h6>
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center mt-2">
                            {graphLoading ? <>

                            </> : <>
                                <p className="mb-0">{ stats.percentageCompletedTask.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}% Completed</p>
                                <p className="mb-0">Recently Completed</p>
                            </>}

                        </div>
                        <div className="d-flex flex-row justify-content-between mt-1">
                            <p><span>{Math.floor( ( new Date(projectDetails.endDate) - new Date())/ (1000 * 60 * 60 * 24)) } Days Remaining</span></p>
                            <p> {new Date(projectDetails.endDate).toLocaleDateString()}</p>
                        </div>
                        <Progress
                            value={stats.percentageCompletedTask}
                            className="progress-lg"
                            style={{
                                height: "22px",
                                backgroundColor: "#0000001F",
                                borderRadius: "24px",
                            }}
                        >
                        </Progress>
                        <div className="d-flex flex-row gap-3 mt-3">
                            <div className="d-flex border-end task-border-color align-items-center">
                                <h6 className="fw-bold mb-0">
                                    {" "}
                                    Total Tasks{" "}
                                </h6>
                                <div className="task-badge mx-2">
                                    <h6 className="fw-bold primary-text-color mb-0">
                                        {completedTasks.totalTasks}
                                    </h6>
                                </div>
                            </div>
                            <div className="d-flex border-end task-border-color align-items-center">
                                <h6 className="fw-bold mb-0"> Pending </h6>
                                <div className="task-badge mx-2">
                                    <h6 className="fw-bold primary-text-color mb-0">
                                        {completedTasks.totalNotStartedTasks}
                                    </h6>
                                </div>
                            </div>
                            <div className="d-flex border-end task-border-color align-items-center">
                                <h6 className="fw-bold mb-0"> Completed </h6>
                                <div className="task-badge mx-2">
                                    <h6 className="fw-bold primary-text-color mb-0">
                                        {completedTasks.totalCompletedTasks}
                                    </h6>
                                </div>
                            </div>

                        </div>
                    </CardBody>
                </Card>
                <div className={"d-flex flex-row flex-md-wrap w-100 gap-2 mb-3"}>
                    <Col className="p-3 rounded-3 flex-grow-1">
                        <Card className="p-3 rounded-3 border w-100 h-100 mb-0">
                            <CardBody className="p-1">
                                <h6 className="primary-text-color fw-bold">
                                    Tasks Completed
                                </h6>
                                <div className="w-100">
                                    {graphLoading ? (
                                        <div className="text-center mt-5 mb-5">
                                            <p className="placeholder-glow">
                                            <span
                                                className="placeholder col-4"
                                                style={{
                                                    width: 150,
                                                    height: 150,
                                                    borderRadius: "50%",
                                                }}
                                            ></span>
                                            </p>
                                        </div>
                                    ) : (
                                        <>
                                            <Bar width={474} height={300} data={tasksCompletedData} options={option} />
                                        </>
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="p-3 rounded-3 flex-grow-1">
                        <Card className="p-3 rounded-3 border w-100 h-100 mb-0">
                            <CardBody className="p-1">
                                <h6 className="primary-text-color fw-bold">
                                    Recently Completed
                                </h6>
                                <table className={"table inventory-cost mt-3"}>
                                    <thead>
                                    <tr>
                                        <th>Task</th>
                                        <th>Date Completed</th>
                                        <th>Duration</th>
                                        <th>Assigned to</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        completedTasksList.length > 0 ? <>
                                            {
                                                completedTasksList.map((task)=>(
                                                    <>
                                                        <tr>
                                                            <td>{task.taskName}</td>
                                                            <td>{new Date(task.endDate).toLocaleDateString()}</td>
                                                            <td>{Math.floor((new Date(task.endDate)- new Date(task.startDate))/ (1000 * 60 * 60 * 24)) } Days</td>
                                                            <td>{task.assignee}</td>
                                                        </tr>
                                                    </>
                                                ))
                                            }
                                        </> : <>
                                            <tr>
                                                <td colSpan={4}>No tasks completed</td>
                                            </tr>
                                        </>
                                    }

                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
            <div className={"container-fluid"}>
                <h6 className="primary-text-color fw-bold">
                    Tasks Progress
                </h6>
                <table className={"table task-progress mt-3 border"}>
                    <thead>
                    <tr>
                        <th>Task</th>
                        <th>Start Date</th>
                        <th>Duration</th>
                        <th>Assigned to</th>
                        <th>Status</th>
                        <th>Progress (%)</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tasksList.length > 0 ? (
                        tasksList.map((task) => (
                            <React.Fragment key={task.taskUniqueIdentifier}>
                                <tr onClick={() => toggleTask(task.taskUniqueIdentifier)}>
                                    <td> {openTask === task.taskUniqueIdentifier ? <>
                                        <FeatherIcon
                                            icon="chevron-right"
                                            className={"table-icon"}
                                        />
                                    </> : <>
                                        <FeatherIcon
                                            icon="chevron-down"
                                            className={"table-icon"}
                                        />
                                    </>}{task.taskName}</td>
                                    <td>{new Date(task.startDate).toLocaleDateString()}</td>
                                    <td>{Math.floor((new Date(task.endDate) - new Date(task.startDate)) / (1000 * 60 * 60 * 24))} Days</td>
                                    <td>{task.assignee}</td>
                                    <td><span className={task.status==="Not Started" ? "badge status-badge": task.status==="Completed" ? "badge complete-status-badge" :  "badge progress-status-badge"}>{task.status}</span></td>
                                    <td>{task.percentageProgress} %</td>
                                </tr>
                                {task.subtasks && task.subtasks.length > 0 && (
                                    task.subtasks.map((subtask, index) => (
                                        <>
                                            <tr onClick={()=>toggleSubTask(subtask.taskUniqueIdentifier)}  key={`${task.taskUniqueIdentifier}-${index}`} className={"subtask"}>
                                                <td> {subtask.subtasks.length > 0 && <>
                                                    {openSubTask === subtask.taskUniqueIdentifier ? <>
                                                        <FeatherIcon
                                                            icon="chevron-right"
                                                            className={"table-icon"}
                                                        />
                                                    </> : <>
                                                        <FeatherIcon
                                                            icon="chevron-down"
                                                            className={"table-icon"}
                                                        />
                                                    </>}
                                                </>}{subtask.taskName}</td>
                                                <td>{new Date(subtask.startDate).toLocaleDateString()}</td>
                                                <td>{Math.floor((new Date(subtask.endDate) - new Date(subtask.startDate)) / (1000 * 60 * 60 * 24))} Days</td>
                                                <td>{subtask.assignee}</td>
                                                <td><span className={subtask.status==="Not Started" ? "badge status-badge": subtask.status==="Completed" ? "badge complete-status-badge" :  "badge progress-status-badge"}>{subtask.status}</span></td>
                                                <td>{subtask.percentageProgress} %</td>
                                            </tr>
                                            {
                                                subtask.subtasks && subtask.subtasks.length > 0 && (
                                                    subtask.subtasks.map((subtask1, subtask1index)=>(
                                                        <>
                                                            <tr key={`${subtask.taskUniqueIdentifier}-${subtask1index}`} className={"subtask1"}>
                                                                <td>{subtask1.taskName}</td>
                                                                <td>{new Date(subtask1.startDate).toLocaleDateString()}</td>
                                                                <td>{Math.floor((new Date(subtask1.endDate) - new Date(subtask1.startDate)) / (1000 * 60 * 60 * 24))} Days</td>
                                                                <td>{subtask1.assignee}</td>
                                                                <td><span className={subtask1.status === "Not Started" ? "badge status-badge" : subtask1.status === "Completed" ? "badge complete-status-badge" : subtask1.status === "delayed" ? "badge delayed-status-badge" : "badge progress-status-badge"}>{subtask1.status}</span></td>
                                                                <td>{subtask1.percentageProgress} %</td>
                                                            </tr>
                                                        </>
                                                    ))
                                                )
                                            }
                                        </>
                                    ))
                                )}

                            </React.Fragment>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={5}>No tasks completed</td>
                        </tr>
                    )}
                    </tbody>

                </table>
            </div>
            </div>
        </>
    )
}